import React, { useEffect, useState } from 'react';
import MembershipAccount from '../../../../../models/membershipAccount';
import Member from '../../../../../models/member';
import { Typography } from '@material-ui/core';
import Spacer from '../../../../Spacer';
import { AccountMemberSelector } from '../../../withdrawal/AccountMemberSelector';
import { FormikProps } from 'formik';
import { DeathForm } from './DeathDialog';

export const ContinuingMemberSelector: React.FC<{
  membershipAccount: MembershipAccount,
  form: FormikProps<DeathForm>
}> = ({ form, membershipAccount }) => {

  const [selectedSurvivingMembers, setSelectedSurvivingMembers] = useState<{ [key: string]: Member }>({});
  const [eligibleRemainingMembers, setEligibleRemainingMembers] = useState<Member[]>([]);

  useEffect(() => {
    const deceasedMemberUIds = form.values.event!.deceasedMembers;
    setEligibleRemainingMembers((membershipAccount.members || []).filter(member => !deceasedMemberUIds.includes(member.uid)));
    setSelectedSurvivingMembers(form.values.transitioningMembers);
  }, [membershipAccount, form]);

  const onTransitioningMemberSelected = (checked: boolean, member: Member) => {
    const temp = form.values.transitioningMembers;

    if (checked && !temp[member.uid]) {
      temp[member.uid] = member;
      form.values.event!.transitioningMembers.push(member.uid);
    } else {
      delete temp[member.uid];
      const index = form.values.event!.transitioningMembers.indexOf(member.uid);
      form.values.event!.transitioningMembers.splice(index, 1);
    }

    form.setFieldValue('remainingMembers', temp);
    setSelectedSurvivingMembers(form.values.transitioningMembers);
  }

  return (<>
    <Typography variant="h3">Continuing Members</Typography>
    <Typography variant="h5">Please select all that apply</Typography>
    <Spacer size={20}/>
    <Typography variant="caption" style={{ textTransform: 'uppercase' }}>Enrolled Members</Typography>
    <Spacer size={10}/>
    <AccountMemberSelector members={eligibleRemainingMembers}
                           selectedMembers={selectedSurvivingMembers}
                           onMemberSelected={onTransitioningMemberSelected}/>
  </>)
}
