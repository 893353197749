import {
  Button,
  Tab,
  Tabs
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '../../../../Dialog';
import { DeathEvent } from '../events/death';
import TabPanel from '../TabPanel';
import { Role } from '../../../../../models/member';
import { useFormik } from 'formik';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import { useMutation, useQueryClient } from 'react-query';
import apiClient from '../../../../../lib/api';
import { useDispatch } from 'react-redux';
import ManualKycStepsRequiredDialog from './ManualKycStepsRequiredDialog';
import { Confirmation } from './Confirmation';
import { ContinuingMemberSelector } from './ContinuingMemberSelector';
import { DeceasedMemberSelector } from './DeceasedMemberSelector';

enum DialogTabs {
  DeceasedMembersTab = 1,
  ContinuingMembersTab = 2,
  ConfirmationTab = 3
};

export interface DeathForm {
  event: DeathEvent | null;
  hasContinuingMembers: string;
  deceasedMembers: Record<string, any>;
  transitioningMembers: Record<string, any>;
}


const DeathDialog: React.FC<{
  open: boolean,
  onDialogClose: Function,
  event: DeathEvent,
  resetDialog: Function,
}> = ({ open, onDialogClose, event, resetDialog }) => {

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [skipContinuingMembers, setSkipContinuingMembers] = useState<boolean>(true);
  const { membershipAccount } = event;
  const [manualKycStepsDialogOpen, setManualKycStepsDialogOpen] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const confirmButtonRef = useRef(null);

  useEffect(() => {
    if (currentTab === DialogTabs.ConfirmationTab) {
      setConfirmDisabled(true);
    }
    if (confirmButtonRef.current) {
      setTimeout(() => {
        setConfirmDisabled(false);
      }, 5000);
    }

  }, [currentTab]);

  const form = useFormik<DeathForm>({
    initialValues: {
      event,
      deceasedMembers: {},
      hasContinuingMembers: '',
      transitioningMembers: {}
    },
    onSubmit: async (values) => {
      handleSubmit()
    },
  });

  const createDeathRequest = useMutation((values: DeathForm) => {
    const deathEvent = values.event;
    const { remainingMembers, deceasedMembers, transitioningMembers } = deathEvent!;
    const membershipAccountUId = deathEvent!.membershipAccount.uid;

    const payload = {
      membershipAccountUId,
      remainingMembers,
      transitioningMembers,
      deceasedMembers
    };

    return apiClient.workflows.startDeathWorkflow(payload);

  });

  const handleSubmit = () => {
    createDeathRequest.mutate(form.values, {
      onSuccess: () => {
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        dispatch(
          showToastMessage({
            message: 'KYC Request sent',
            type: 'success',
          }),
        );
        setManualKycStepsDialogOpen(true);
      },
      onError: (error: any) => {
        handleCloseDialog();
        dispatch(
          showToastMessage({
            message: `Error submitting KYC request ${
              error.message ? `. ${error.message}` : ''
            }`,
            type: 'error',
          }),
        );
      },
    });
  }

  const handleNextClicked = () => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      if (form.values.hasContinuingMembers === 'yes') {
        setCurrentTab(DialogTabs.ContinuingMembersTab);
      } else {
        traverseToConfirmationTab();
      }
    }
    if (currentTab === DialogTabs.DeceasedMembersTab &&
      form.values.hasContinuingMembers === 'no') {
    }
    if (currentTab === DialogTabs.ContinuingMembersTab) {
      traverseToConfirmationTab();
    }
  }

  const traverseToConfirmationTab = () => {
    setCurrentTab(DialogTabs.ConfirmationTab);
    isConfirmationDisabled();
  }

  const handleCloseDialog = () => {
    form.resetForm();
    resetDialog();
    onDialogClose();
  }

  const handleBackClicked = () => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      resetDialog();
    }
    if (currentTab === DialogTabs.ContinuingMembersTab) {
      setCurrentTab(DialogTabs.DeceasedMembersTab);
    }
    if (currentTab === DialogTabs.ConfirmationTab && !skipContinuingMembers) {
      setCurrentTab(DialogTabs.ContinuingMembersTab);
    }
    if (currentTab === DialogTabs.ConfirmationTab && skipContinuingMembers) {
      setCurrentTab(DialogTabs.DeceasedMembersTab);
    }
    isConfirmationDisabled();
  }

  const isNextDisabled = (): boolean => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      return form.values.event === null ||
        (membershipAccount.members!.length > 1 && form.values.hasContinuingMembers === '');
    } else if (currentTab === DialogTabs.ContinuingMembersTab) {
      // Enforce spouse selection to continue
      const spouse = membershipAccount.members?.find(m => m.role === Role.Admin);
      const accountOwner = membershipAccount.members?.find(m => m.role === Role.AccountOwner);
      const deceasedAndSurvivingMembers = [...form.values.event?.deceasedMembers!,
        ...form.values.event?.transitioningMembers!];

      // If the spouse is indicated as deceased the account owner must be selected
      if (spouse && form.values.event?.deceasedMembers.includes(spouse.uid)) {
        return !form.values.event?.transitioningMembers!.includes(accountOwner!.uid);
      } // Spouse is not deceased so they must be selected
      else if (spouse && !form.values.event?.deceasedMembers.includes(spouse.uid)) {
        return !form.values.event?.transitioningMembers!.includes(spouse.uid);
      }


      // Shouldn't be the case that there are others but no spouse
      return deceasedAndSurvivingMembers.length < 1;
    }
    return false;
  }

  const renderButtons = () => {
    const backButton = (
      <Button
        key="back"
        color="default"
        variant="contained"
        style={{ marginRight: '15px', justifySelf: 'flex-start' }}
        onClick={handleBackClicked}
      >
        Back
      </Button>
    );
    const nextButton = (currentTab !== DialogTabs.ConfirmationTab && <Button
      key="next"
      color="primary"
      variant="contained"
      style={{ marginRight: '15px' }}
      disabled={isNextDisabled()}
      hidden={currentTab === DialogTabs.ConfirmationTab}
      onClick={handleNextClicked}
    >
      Next</Button>);

    const confirmButton = (currentTab === DialogTabs.ConfirmationTab && <Button
      key="confirm"
      color="primary"
      variant="contained"
      style={{ marginRight: '15px' }}
      ref={confirmButtonRef}
      disabled={confirmDisabled || createDeathRequest.isLoading}
      onClick={handleSubmit}
    >
      Confirm</Button>);

    const cancelButton = (<Button
      key="cancel"
      variant="contained"
      onClick={handleCloseDialog}
      color="default"
      disabled={createDeathRequest.isLoading}
    >
      Close
    </Button>);
    return [backButton, nextButton, confirmButton, cancelButton];
  };

  const onCloseManualKycStepsDialog = () => {
    setManualKycStepsDialogOpen(false);
    handleCloseDialog();
  }

  const isConfirmationDisabled = () => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      setConfirmDisabled(form.values.event!.deceasedMembers.length < 1 || form.values.hasContinuingMembers === '');
    } else if (currentTab === DialogTabs.ContinuingMembersTab) {
      setConfirmDisabled(form.values.event!.transitioningMembers.length < 1);
    }
  }

  const handleContinuingTabClicked = () => {
    if (currentTab === DialogTabs.ConfirmationTab) {
      handleBackClicked();
    }
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      handleNextClicked();
    }
  }

  const onSkipContinuingMembers = (skipContinuingMemberSelection: boolean) => {
    setSkipContinuingMembers(skipContinuingMemberSelection);

    if (!skipContinuingMembers) {
      form.setFieldValue('transitioningMembers', {});
      form.values.event!.transitioningMembers = [];
    }

  }


  return (
    <>
      <Dialog
        open={open}
        title={'Life Event: Death'}
        maxWidth="xl"
        disableBackdropClick
        onClose={onDialogClose}
        buttons={renderButtons()}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            height: 700,
            width: 1200,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            aria-label="Life event tabs"
            value={currentTab}
            indicatorColor="primary"
            style={{ overflow: 'visible' }}
          >
            <Tab label="Death"
                 value={1}
                 disabled={isNextDisabled()}
                 onClick={() => setCurrentTab(1)}
            />
            <Tab label="Continuing"
                 value={2}
                 disabled={isNextDisabled() || skipContinuingMembers}
                 onClick={() => handleContinuingTabClicked()}
            />
            <Tab label="Confirmation"
                 value={3}
                 disabled={confirmDisabled}
                 onClick={() => handleNextClicked()}
            />
          </Tabs>
          <TabPanel value={currentTab} index={1}>
            <DeceasedMemberSelector form={form}
                                    onSkipContinuingMembers={onSkipContinuingMembers}
                                    membershipAccount={membershipAccount}/>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <ContinuingMemberSelector membershipAccount={membershipAccount} form={form}/>
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <Confirmation membershipAccount={membershipAccount} form={form}/>
          </TabPanel>
        </div>
      </Dialog>
      <ManualKycStepsRequiredDialog
        onDialogClose={onCloseManualKycStepsDialog}
        open={manualKycStepsDialogOpen}
      />
    </>
  );
}

export default DeathDialog;
