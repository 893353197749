import { BaseEvent } from './base';
import { LifeEventName } from './constants';
import MembershipAccount from '../../../../../models/membershipAccount';

export class DeathEvent extends BaseEvent {
  transitioningMembers: string[];
  deceasedMembers: string[];
  remainingMembers: string[];

  constructor(membershipAccount: MembershipAccount) {
    super(LifeEventName.Death, membershipAccount);
    this.transitioningMembers = [];
    this.deceasedMembers = [];
    this.remainingMembers = [];
  }
}
