import MembershipAccount from '../../../../models/membershipAccount';
import Dialog from '../../../Dialog';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DeathEvent } from './events/death';
import { LifeEventName, LifeEventOption, lifeEventOptions } from './events/constants';
import DeathDialog from './death/DeathDialog';
import Member from '../../../../models/member';

interface LifeEventProps {
  membershipAccount: MembershipAccount;
  onDialogClose: Function;
  open: boolean;
}

const LifeEvents: React.FC<LifeEventProps> = ({
                                                membershipAccount,
                                                onDialogClose,
                                                open

                                              }) => {
  const [selectedLifeEvent, setSelectedLifeEvent] = useState<DeathEvent | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [account, setAccount] = useState<MembershipAccount | null>(null);

  useEffect(() => {
    // Gets member program enrollments
    const members = membershipAccount.members?.map((member: Member) => {
      const programEnrollments = membershipAccount.getProgramEnrollmentsByMemberUid(member.uid) || [];
      return { ...member, programEnrollments }
    });

    setAccount({...membershipAccount, members});
  }, [membershipAccount]);

  const handleNextClicked = () => {
    if (selectedOption === LifeEventName.Death) {
      setSelectedLifeEvent(new DeathEvent(account!));
    }
  }

  const resetDialog = () => {
    setSelectedLifeEvent(null);
    setSelectedOption(null);
  }

  const handleSelectedOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
  }


  const handleCancelClicked = () => {
    setSelectedLifeEvent(null);
    setSelectedOption(null);
    resetDialog();
    onDialogClose();
  }

  const renderButtons = () => {
    const nextButton = (<Button
      key="next"
      color="primary"
      variant="contained"
      style={{ marginRight: '15px' }}
      onClick={handleNextClicked}
      disabled={selectedOption === null}
    >
      Next</Button>);

    const cancelButton = (<Button
      key="cancel"
      variant="contained"
      onClick={handleCancelClicked}
      color="default"
    >
      Close
    </Button>);

    return [nextButton, cancelButton];
  };

  return (<>
      {!selectedLifeEvent && <Dialog
        open={open}
        title='Life Event'
        disableBackdropClick
        maxWidth="xl"
        onClose={onDialogClose}
        buttons={renderButtons()}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            height: 700,
            width: 1200,
          }}
        >
          <form>
            <FormControl variant="outlined" fullWidth>
              <FormLabel id="life-event-radio-buttons-label">Please select a life event</FormLabel>
              <RadioGroup
                aria-labelledby="life-event-radio-buttons-label"
                name="selectedLifeEventUId"
                value={selectedOption}
                onChange={handleSelectedOptionChanged}
              >
                {lifeEventOptions
                  .map((option: LifeEventOption) => (
                    <FormControlLabel
                      value={option.value}
                      key={option.value}
                      control={<Radio/>}
                      label={option.value}
                      disabled={option.value !== LifeEventName.Death}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </form>
        </div>
      </Dialog>
      }
      {selectedLifeEvent && selectedLifeEvent.name === LifeEventName.Death &&
        <DeathDialog
          open={open}
          onDialogClose={onDialogClose}
          resetDialog={resetDialog}
          event={selectedLifeEvent}
        />
      }
    </>
  );
}

export default LifeEvents;
