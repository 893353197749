import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import Member, { Role } from '../../../models/member';
import Person from '../../../models/person';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ProgramEnrollment from '../../../models/programEnrollment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 'bold',
      marginRight: '3px',
      textTransform: 'capitalize'
    },
    memberCard: { margin: '1rem 1rem 1rem 0',       backgroundColor: theme.backgroundColors.body, },
    cardContent: {
      opacity: 0.6,
      display: 'flex',
      justifyContent: 'flex-start',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
      backgroundColor: theme.backgroundColors.body,
      padding: '4px 12px 12px!important',
      margin: 0,
    },
    checkBoxLabel: {
      marginBottom: '0',
    },
    memberName: {
      marginTop: '4px',
      fontWeight: 'bold',
    },
  }),
);

interface MemberSelectorProps {
  member: Member;
  selectable: boolean;
  onMemberSelected?: Function;
  selectedMembers?: { [key: string]: Member };
}

const MemberName = ({ member, isCheckboxLabel }: { member: Member; isCheckboxLabel?: boolean }) => {
  const classes = useStyles();
  const [age, setAge] = useState<number | undefined>(undefined);

  useEffect(() => {
    const dob = (member.person as Person).dob;

    if (dob) {
      setAge(new Date().getFullYear() - new Date(dob).getFullYear());
    }

  }, [member]);

  return (
    <>
      <Typography
        variant="body1"
        className={clsx([classes.memberName, isCheckboxLabel && classes.checkBoxLabel])}
      >
        {(member.person as Person).firstName} {(member.person as Person).lastName}
      </Typography>
      <Typography variant="caption">{(member.person as Person).gender}{age &&
        <span>, {age} years old</span>}</Typography>
    </>
  );
};

export const MemberSelector: React.FC<MemberSelectorProps> = ({
                                                                member,
                                                                selectable,
                                                                onMemberSelected,
                                                                selectedMembers,
                                                              }) => {
  const classes = useStyles();

  const [activeEnrollment, setActiveEnrollment] = useState<ProgramEnrollment | null>(null);

  useEffect(() => {
    setActiveEnrollment(member.programEnrollments?.filter(pe => !pe.ended)[0] || null);
  }, [member]);

  return (
    <Card className={classes.memberCard}>
      <CardContent className={classes.cardContent}>
        <div style={{ flexGrow: 1 }}>
          {selectable && (
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  name="memberSelector"
                  checked={selectedMembers && selectedMembers.hasOwnProperty(member.uid)}
                  color="primary"
                  onChange={event =>
                    onMemberSelected && onMemberSelected(event.target.checked, member)
                  }
                />
              }
              label={<MemberName member={member} isCheckboxLabel={true}/>}
            />
          )}
          {!selectable && <MemberName member={member}/>}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          flexGrow: 1,
          marginLeft: '10px'
        }}>
          <Typography variant="overline">{member.role === Role.AccountOwner &&
            <MonetizationOnOutlinedIcon style={{ color: 'green', fontSize: 'large' }}/>}{member.role.replaceAll('_', ' ')}</Typography>
          {activeEnrollment && <Typography variant="overline">{activeEnrollment.role.replaceAll('_', ' ')}</Typography>}
          <Typography variant="overline">DEP {member.dependentNumber}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};
