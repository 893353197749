import React, { useEffect, useState } from 'react';
import MembershipAccount from '../../../../../models/membershipAccount';
import Member from '../../../../../models/member';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import Spacer from '../../../../Spacer';
import { AccountMemberSelector } from '../../../withdrawal/AccountMemberSelector';
import { DeathForm } from './DeathDialog';
import { FormikProps } from 'formik';

export const DeceasedMemberSelector: React.FC<{
  onSkipContinuingMembers: Function,
  membershipAccount: MembershipAccount,
  form: FormikProps<DeathForm>
}> = ({ onSkipContinuingMembers, membershipAccount, form }) => {

  const [selectedDeceasedMembers, setSelectedDeceasedMembers] = useState<{ [key: string]: Member }>({});

  useEffect(() => {
    setSelectedDeceasedMembers(form.values.deceasedMembers);
  }, [form]);

  const onDeceasedMemberSelected = (checked: boolean, member: Member) => {
    const temp = form.values.deceasedMembers;

    if (checked && !temp[member.uid]) {
      temp[member.uid] = member;
      form.values.event!.deceasedMembers.push(member.uid);
    } else {
      delete temp[member.uid];
      const index = form.values.event!.deceasedMembers.indexOf(member.uid);
      form.values.event!.deceasedMembers.splice(index, 1);
    }

    form.setFieldValue('deceasedMembers', temp);
    setSelectedDeceasedMembers(form.values.deceasedMembers);
  }

  const isShowSurvivingMembersQuestion = (): boolean => {
    // Display when deceased members have been selected and the account has other enrollments
    return form.values.event!.deceasedMembers.length > 0 && membershipAccount.members!.length > 1;
  }

  const handleSurvivingMembersQuestionOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hasContinuingMembers = event.target.value;
    form.setFieldValue('hasContinuingMembers', hasContinuingMembers);

    if (hasContinuingMembers === 'yes') {
      onSkipContinuingMembers(false);
    } else {
      onSkipContinuingMembers(true);
    }
  }

  return (<>
    <Typography variant="h3">Deceased Members</Typography>
    <Typography variant="h5">Please select all that apply</Typography>
    <Spacer size={20}/>
    <Typography variant="caption" style={{ textTransform: 'uppercase' }}>Enrolled Members</Typography>
    <Spacer size={10}/>
    <AccountMemberSelector members={membershipAccount.members || []} selectedMembers={selectedDeceasedMembers}
                           onMemberSelected={onDeceasedMemberSelected}/>
    <Spacer size={5}/>
    {isShowSurvivingMembersQuestion() && <FormControl variant="outlined" fullWidth>
      <FormLabel id="surviving-members-question">Do surviving members wish to continue
        membership?</FormLabel>
      <RadioGroup
        aria-labelledby="surviving-members-question"
        name="continuingMembers"
        value={form.values.hasContinuingMembers}
        onChange={handleSurvivingMembersQuestionOptionChanged}
      >
        <FormControlLabel
          value="yes"
          control={<Radio/>}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio/>}
          label="No"
        />
      </RadioGroup>
    </FormControl>
    }
  </>);
}
