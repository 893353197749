import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import Member from '../../models/member';
import MemberCard from './memberInfo/MemberCard';
import MembershipAccount from '../../models/membershipAccount';

interface AccountMemberProps {
  handleMemberOpen: (uid: string) => void;
  membershipAccount: MembershipAccount;
  KYCStatus: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    cards: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px',
    },
  }),
);

const AccountMembers: React.FC<AccountMemberProps> = ({
  handleMemberOpen,
  membershipAccount,
  KYCStatus,
}) => {
  const styles = useStyles();

  if (!membershipAccount || !membershipAccount.members) {
    return null;
  }
  return (
    <section>
      <Typography variant="h3">Members</Typography>
      <div className={styles.cards}>
        {membershipAccount.members.map((member: Member, index: number) => {
          return (
            <MemberCard
              key={index}
              handleMemberOpen={() => handleMemberOpen(member.uid)}
              member={member}
              membershipAccountUId={membershipAccount.uid}
              programEnrollments={
                membershipAccount.getProgramEnrollmentsByMemberUid(member.uid) || []
              }
              saMemberProgramsUid={
                membershipAccount.memberPrograms
                  ?.filter(mp => mp.programLevel.name.includes('SA'))
                  .map(m => m.uid) || []
              }
              KYCStatus={KYCStatus}
            />
          );
        })}
      </div>
    </section>
  );
};

export default AccountMembers;
