import React, { useEffect, useState } from 'react';
import MembershipAccount from '../../../../../models/membershipAccount';
import Member, { Role } from '../../../../../models/member';
import { Alert, AlertTitle } from '@material-ui/lab';
import Spacer from '../../../../Spacer';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { AccountMemberList } from '../../../withdrawal/AccountMemberList';
import { FormikProps } from 'formik';
import { DeathForm } from './DeathDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      textTransform: 'uppercase'
    },
  }),
);

export const Confirmation: React.FC<{
  membershipAccount: MembershipAccount,
  form: FormikProps<DeathForm>
}> = ({ membershipAccount, form }) => {

  const [withdrawingMembers, setWithdrawingMembers] = useState<Member[]>([]);
  const [spouseContinuing, setSpouseContinuing] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    const deceasedAndSurvivingMembers = [...form.values.event?.deceasedMembers!,
      ...form.values.event?.transitioningMembers!];

    const withdrawingMembers = membershipAccount.members!.filter(m => !deceasedAndSurvivingMembers.includes(m.uid));

    setWithdrawingMembers(withdrawingMembers);
    form.values.event!.remainingMembers = withdrawingMembers.map(m => m.uid);


    const spouse = membershipAccount.members?.find(m => m.role === Role.Admin);

    if (spouse) {
      setSpouseContinuing(form.values.event!.transitioningMembers.includes(spouse.uid));
    }


  }, [membershipAccount, form]);

  return (<>
    {spouseContinuing && <Alert severity="warning" variant="outlined">
      <AlertTitle>New Share Account Required</AlertTitle>
      Continue to request KYC check for surviving spouse.
    </Alert>}
    {!spouseContinuing && <Alert severity="warning" variant="outlined" >
      <AlertTitle>New Share Account Not Required</AlertTitle>
      No KYC Required.
    </Alert>}

    {form.values.hasContinuingMembers === 'yes' && <section>
      <Spacer size={15}/>
      <Typography variant="caption" className={classes.caption}>Continuing Members</Typography>
      <Spacer size={5}/>
      <AccountMemberList
        members={membershipAccount.members!.filter(m => form.values.event!.transitioningMembers.includes(m.uid))}/>
    </section>
    }

    {withdrawingMembers.length > 0 && <section>
      <Spacer size={15}/>
      <Typography variant="caption" className={classes.caption}>Withdrawing Members</Typography>
      <Spacer size={5}/>
      <AccountMemberList
        members={withdrawingMembers}/>
    </section>
    }
    <section>
      <Spacer size={15}/>
      <Typography variant="caption" className={classes.caption}>Deceased Members</Typography>
      <Spacer size={5}/>
      <AccountMemberList
        members={membershipAccount.members!.filter(m => form.values.event!.deceasedMembers.includes(m.uid))}/>
    </section>

  </>);
}
